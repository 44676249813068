<template>
	<div class="layout-invoice-page">
    <Button type="button" label="Yazdır" icon="pi pi-print" @click="printClick" class="p-button-secondary mb-3"></button>

    <div class="grid layout-invoice-content">
        <div class="col-12">
            <div class="card">
                <div class="flex justify-content-between">
                    <h5>{{ savedQueryName }}</h5>
                </div>
                <div class="p-0">
					<DataTable ref="dt"
                        :value="data"
                        :lazy="true"
                        :paginator="false"
                        :rows="1000"
                        :loading="loading"
                        :scrollable="true"
                        :resizableColumns="true" columnResizeMode="expand" 
                        class="p-invoice-datatable-responsive p-datatable-sm"
                        responsiveLayout="scroll">
                        <template #empty>
                            Kayıt Bulunamadı.
                        </template>
                        <template #loading>
                            Veriler yükleniyor, lütfen bekleyiniz...
                        </template>
                        <Column v-for="(col, index) in data_columns" :field="col.name" :header="col.label" :key="index" :sortable="false" :style="'flex: 0 0 ' + col.width + '%;'" >
                            <template #body="slotProps">
                                <div v-html="columnFieldFormat(slotProps.data, col)" /> 
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import CrmService from "../service/CrmService";
import { FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
            loading: true,
            crmService: null,
            dataTableKey: 0,

            data: [],
            selectedItem: [],
            data_columns: [],
			lazyParams: {},
            filters: {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			},
            totalRecords: 0,
            options: {
                searchAttributes: ["bm_kurulummusteriidname", "bm_frmno", "bm_serino", "bm_po", "bm_urunidname", "bm_siparisnoidname", "bm_teklifnoidname", "bm_markaname", "bm_satisyapanfirmaname", "bm_turuname"],
            },
		}
	},
    async created() {
		this.crmService = new CrmService();
        if (this.options.views) {
            debugger;

            this.savedQueryId = this.options.views[0].savedQueryId;
            this.savedQueryName = this.options.views[0].viewName;

            if (this.options.forceSavedQueryId != undefined) {
                const filteredView = this.options.views.find(x => x.savedQueryId == this.options.forceSavedQueryId);
                if (filteredView) {
                    this.savedQueryId = filteredView.savedQueryId;
                    this.savedQueryName = filteredView.viewName;
                }
            }

            this.options.views.forEach(element => {
                this.menu_items[0].items.push(
                    {
                        label: element.viewName,
                        icon: 'pi pi-bars',
                        command: () => {
                            this.savedQueryId = element.savedQueryId;
                            this.savedQueryName = element.viewName;
                            this.loadLazyData();
                        }
                    }
                );
            });
        }

        if (this.options.filterParent) {
            console.log(this.options.filterParent);
            if (this.options.filterParent.length > 0) {
                if (this.options.filterParent[0].value == undefined) {
                    return false;
                }
            }
        }
        debugger;

        //PREVENT SYSTEM VIEWS
        /*
        let preventSystemViews = true;
        if (this.options.preventSystemViews) {
            if (this.options.preventSystemViews == true) {
                preventSystemViews = false;
            }
        }

        console.log(preventSystemViews);
        */

        /*
        if (preventSystemViews) {
            await this.getSystemViews(this.baseEntityName);
        }
        */
        
        //PREVENT USER VIEWS
        /*
        let preventUserViews = true;
        if (this.options.preventUserViews) {
            if (this.options.preventUserViews == true) {
                preventUserViews = false;
            }
        }
        
        if (preventUserViews) {
            this.menu_items.push({ label: 'Kullanıcı Görünümleri', items: [] });
            await this.getUserViews(this.baseEntityName);
        }
        */
	},
    computed: {
		baseEntityName() {
			debugger;
			return this.$route.params.baseEntityName;
		},
        savedQueryId() {
			debugger;
			return this.$route.params.savedQueryId;
		},
        savedQueryName() {
			debugger;
			return this.$route.params.savedQueryName;
		},
    },
    mounted: function () {
		this.loading = true;
				
		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters
		};

		this.loadLazyData();

        window.addEventListener("message", (event)=>{
			var origin = event.origin;

			if (window.origin == origin && event.isTrusted && event.data.tag != undefined) {
				debugger;
				if (event.data.tag == "refresh") {
					this.loadLazyData();
				}
			}
		});
	},
    methods: {
        printClick() {
			window.print();
		},
		loadLazyData() {
            if (this.savedQueryId) {
                debugger;
                this.loading = true;
                this.lazyParams["baseEntityName"] = this.baseEntityName;
                this.lazyParams["SavedQueryId"] = this.savedQueryId;
                this.lazyParams["Options"] = this.options;
                this.lazyParams.filters = this.filters;

                debugger;
                this.crmService.GetViewData(this.lazyParams)
                .then(data => {
                    if (data == undefined) {
                        alert('Json Verisi yüklenemedi!');
                    }
                    else {
                        debugger;
                        /*
                        this.salesorders = data.jsonData;
                        for (let index = 0; index < data.jsonData.length; index++) {
                            this.salesorders[index].olusturmaTarihi = new Date(this.salesorders[index].olusturmaTarihi);
                        }
                        */

                        if (data.hata) {
                            this.$toast.add({severity:'error', summary: 'Erişim Hatası', detail: data.hataAciklamasi, life: 5000});
                        }
                        else {
                            this.data = data.data;

                            const printArray = JSON.parse(localStorage.getItem(this.baseEntityName + '_print_array'));
                            if (printArray) {
                                if (printArray.length > 0) {
                                    
                                    // entityId değerlerini içeren bir Set oluştur
                                    const entityIdSet = new Set(printArray);

                                    // Görüntülenen data'yı entityId'ye göre filtrele
                                    const filteredData = this.data.filter(item => entityIdSet.has(item.entityId));
                                    this.data = filteredData;
                                }
                            }


                            this.data_columns = data.columns;
                            this.columnSizeDuzelt();
                            this.totalRecords = data.totalRecords;
                            this.loading = false;
                            this.$emit('totalRecords', data.totalRecords);
                        }
                    }
                })
                .catch(error => {
                    debugger;
                    console.log(error);
                    this.$toast.add({severity:'error', summary: 'Sistemsel Erişim Hatası', detail: this.baseEntityName + ' - ' + error, life: 5000});
                });
            }
        },
        columnFieldFormat(data, col){
            debugger;
            const lookupData = data[col.name + "lookupData"];
            if (lookupData) {
                const lookupFieldListesi = ["account", "contact", "product", "bm_unite", "incident", "bm_yedekparca"];
                const logicalName = lookupData.split('|')[0];
                if (lookupFieldListesi.includes(logicalName)) {
                    const entityId = lookupData.split('|')[1];
                    const entityUrl = process.env.VUE_APP_URL + "#/" + logicalName + "/" + entityId;
                    return "<a class='lookuplink' href='" + entityUrl + "' target='_blank'> " + data[col.name] + "</a>";
                }
                else {
                    return data[col.name];
                }
            }
            else {
                if (this.baseEntityName == 'bm_yedekparcateklifsiparis' && col.name == 'bm_pesinmi' && data["bm_pesinmi"] == 'Evet') {
                    return "<div class='dd'>" + data[col.name] + "</div>";
                }
                else {
                    return data[col.name];
                }
            }
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
			debugger;
            this.loadLazyData();
        },
        onSortChange(event){
            const value = event.value.value;
            const sortValue = event.value;

            if (value.indexOf('!') === 0) {
                this.sortOrder = -1;
                this.sortField = value.substring(1, value.length);
                this.sortKey = sortValue;
            }
            else {
                this.sortOrder = 1;
                this.sortField = value;
                this.sortKey = sortValue;
            }
        },
        OnOzelIslem(){
            this.$emit('onOzelIslem', this.selectedItem);
        },
        columnSizeDuzelt(){
            let toplamWidth = 0;
            if (this.data_columns) {
                this.data_columns.forEach(element => {
                    toplamWidth += parseInt(element.width);
                });

                this.data_columns.forEach(element => {
                    element.width = parseFloat((parseFloat(element.width) / toplamWidth) * 100);
                });
            }
        }
	},
}
</script>

<style scoped>
    @page { size: landscape; }
</style>
